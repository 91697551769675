import React from 'react'
import styled from '@emotion/styled'
import { H2, H4 } from '../components/shared/typography'
import { Link as GatsbyLink } from 'gatsby'
import { Section, GridContainer, GridItem } from '../components/shared/section'
import { colors, spacing } from '../styles/variables'
import Layout from '../components/layout'
import SEO from '../components/seo'
import { useArchiveJobs } from '../hooks/use-archives-jobs'

const Job = styled(GridItem)`
  min-height: 240px;
  width: 100%;
  display: flex;
  justify-content: stretch;
  align-items: stretch;
  text-align: center;
  position: relative;
  a {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    flex: 1 1 100%;
    padding: ${ spacing.sm }px;
    background-color: rgba(0,0,0,0.05);
    text-decoration: none;
    color: ${ colors.purple } ;
    h4 {
      margin: 0 auto;
    }
  }
`

const CaseStudies = () => {
  const { edges } = useArchiveJobs()
  const jobs = edges
  return (
    <Layout>
      <SEO title="Careers" />
      <Section fullpage>
        <h1 className="sr-only">Careers</h1>
        <H2 className="md:pt-20 pt-10">Be Passionate. Be Awesome. Work Smart. Work Here.</H2>
        <GridContainer>
          {jobs.map(job => (
            <Job span="4">
              <GatsbyLink to={`/career/${ job.node.slug }`} >
                <H4>
                  {job.node.jobTitle}
                </H4>
              </GatsbyLink>
            </Job>
          ))}
        </GridContainer>
      </Section>
    </Layout>
  )
}

export default CaseStudies
