import { useStaticQuery, graphql } from 'gatsby'

export const useArchiveJobs = () => {
  const { allContentfulSingleCareerPage } = useStaticQuery(
    graphql`
      query {
        allContentfulSingleCareerPage {
          edges {
            node {
              id
              jobTitle
              link
              slug
            }
          }
        }
      }
    `
  )
  return allContentfulSingleCareerPage
}
